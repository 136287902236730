import { Locale, type LocalizedString } from "../Locale.js";
import type { namesOfRequiredCategories } from "../namesOfRequiredCategories.js";

export const categoryPathMap = {
	cakes: {
		[Locale.cs]: "dorty" as const,
	},
	christmas: {
		[Locale.cs]: "vanoce" as const,
	},
	desserts: {
		[Locale.cs]: "zakusky" as const,
	},
	// TODO SD-705
	// extras: {
	// 	[Locale.cs]: "doplnky" as const,
	// },
	fillings: {
		[Locale.cs]: "naplne-dortu" as const,
	},
	minidesserts: {
		[Locale.cs]: "minidezerty" as const,
	},
	valentines: {
		[Locale.cs]: "valentyn" as const,
	},
} satisfies Record<keyof typeof namesOfRequiredCategories, LocalizedString>;
